// @file Utils for processing posts on Surface and Slideshow

import device from '@@/bits/device'
import { $ } from '@@/bits/jquery'
import { getHostnameFromUrl, transformUrl } from '@@/bits/location'
import { mathifyElement } from '@@/bits/math'
import { PADLET_DOMAIN_REGEX } from '@@/bits/regex'
import {
  isInternalUrl,
  isProfileUrlFormat,
  isSlideshowUrlFormat,
  isWallOrWallWishUrlFormat,
  NATIVE_HOST,
} from '@@/bits/url'
import { BRIDGED_LINK_TYPE_ATTRIBUTE } from '@@/native_bridge/anchor'
import type { NativeAppOpenLink } from '@@/pinia/native_app'
import { MENTION_ELEMENT_TAG_NAME } from '@padlet/universal-post-editor'

const setRedirectUrlsForExternalLinks = (element: HTMLElement): void => {
  $(element)
    .find('a[href^=h]')
    .attr({ target: '_blank', rel: 'noopener nofollow ugc' })
    .each((_, el) => {
      const href = el.getAttribute('href')?.trim() ?? ''
      if (!isInternalUrl(href)) {
        el.setAttribute('href', transformToSafeRedirectUrl(href))
      }
    })
}

const transformToSafeRedirectUrl = (url: string): string => {
  return transformUrl(`https://${NATIVE_HOST}`, {
    path: 'redirect',
    search: {
      url,
    },
  })
}

const setExternalLinksForNativeBridge = (element: HTMLElement): void => {
  $(element)
    .find('a[href^=h]')
    .attr({ target: '_blank', rel: 'noopener nofollow ugc', [BRIDGED_LINK_TYPE_ATTRIBUTE]: 'external' })
}

const isMentionLink = (el: Element): boolean => {
  return el.tagName === 'A' && el.parentElement?.tagName === MENTION_ELEMENT_TAG_NAME.toUpperCase()
}

// check to see if the url links to a padlet and set data-bridged-link to internal
const makeBodyPadletLinksInternalForNativeBridge = (element: HTMLElement): void => {
  if (!device.app) return

  const bodyAnchorTags = element.querySelectorAll('a[href^=h]')
  for (const anchorTag of bodyAnchorTags) {
    const hrefUrl = anchorTag.getAttribute('href')
    if (hrefUrl == null) continue

    const hrefHost = getHostnameFromUrl(hrefUrl)
    if (!PADLET_DOMAIN_REGEX.test(hrefHost)) return

    if (isMentionLink(anchorTag)) {
      anchorTag.setAttribute(BRIDGED_LINK_TYPE_ATTRIBUTE, 'profile')
    } else if (isSlideshowUrlFormat(hrefUrl)) {
      anchorTag.setAttribute(BRIDGED_LINK_TYPE_ATTRIBUTE, 'slideshow')
    } else if (isWallOrWallWishUrlFormat(hrefUrl)) {
      anchorTag.setAttribute(BRIDGED_LINK_TYPE_ATTRIBUTE, 'padlet')
    }
  }
}

/**
 * Generates the payload for internal links to be opened in the native app.
 *
 * @param link - The URL of the internal link
 * @returns An object containing the link type, content category, and subcategory (if applicable)
 *
 * NOTE: When adding new cases for handling URLs, the order of checks should be based on the specificity of the path.
 * If a new link type is a subset of an existing regex check, it must be placed after the corresponding conditional.
 * This ensures that more specific cases are handled before more general ones.
 */
const getNativeLinkPayloadForInternalLink = (link: string): Omit<NativeAppOpenLink, 'url'> => {
  if (isSlideshowUrlFormat(link)) {
    return { linkType: 'internal', contentCategory: 'page', contentSubcategory: 'slideshow' }
  }
  if (isWallOrWallWishUrlFormat(link)) {
    return { linkType: 'internal', contentCategory: 'page', contentSubcategory: 'padlet' }
  }
  if (isProfileUrlFormat(link)) {
    return { linkType: 'internal', contentCategory: 'page', contentSubcategory: 'profile' }
  }
  // could be dashboard internal links or other subcategories that we don't handle in the native app yet
  return { linkType: 'internal', contentCategory: 'page' }
}

const makeBodyLinksOpenInExternalBrowserForZoomApp = async (element: HTMLElement): Promise<void> => {
  const zoomSdk = (await import('@zoom/appssdk')).default
  element.querySelectorAll('a').forEach((a: HTMLAnchorElement) => {
    a.addEventListener('click', (e: MouseEvent) => {
      const href = a.href
      if (href == null) return
      e.preventDefault()
      void zoomSdk.openUrl({ url: href })
    })
  })
}

function mathifyPostBody(element: HTMLElement): void {
  const mathEls = element.querySelectorAll('var')
  if (mathEls.length > 0) {
    mathEls.forEach((el) => {
      if (el.firstElementChild == null || el.firstElementChild.className !== 'katex') {
        void mathifyElement(el)
      }
    })
  }
}

const MATCH_ANY_WORD_REGEX = /\w+/g
function getNumberOfWords(text?: string | null): number {
  if (text == null || text.length === 0) return 0
  const matches = text.match(MATCH_ANY_WORD_REGEX)
  return matches != null ? matches.length : 0
}

export {
  getNativeLinkPayloadForInternalLink,
  getNumberOfWords,
  isMentionLink,
  makeBodyLinksOpenInExternalBrowserForZoomApp,
  makeBodyPadletLinksInternalForNativeBridge,
  mathifyPostBody,
  setExternalLinksForNativeBridge,
  setRedirectUrlsForExternalLinks,
  transformToSafeRedirectUrl,
}
