// @file Load and transform Math equations written in Latex
import { loadCss, loadJs } from '@@/bits/load'

declare const katex: any

const scriptUrl = 'https://padlet.net/ajax/libs/KaTeX/0.15.2/katex.min.js'
const styleUrl = 'https://padlet.net/ajax/libs/KaTeX/0.15.2/katex.min.css'

export async function mathifyElement(elem: HTMLElement): Promise<void> {
  await Promise.all([loadJs(scriptUrl), loadCss(styleUrl)])
  katex.render(elem.innerText, elem, { displayMode: false })
}

export async function mathifyPostBody(element: HTMLElement): Promise<void> {
  const mathEls = element.querySelectorAll('var')

  if (mathEls.length > 0) {
    mathEls.forEach((el) => {
      if (el.firstElementChild == null || el.firstElementChild.className !== 'katex') {
        void mathifyElement(el)
      }
    })
  }
}
